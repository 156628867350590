// Global imports
import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router';
import _ from 'lodash';

// Project imports
import {getLatestSnapshotDate} from 'ki-common/utils/snapshotDates';

// Local imports
import {KiProgressBar, KiButtonSpinner} from 'components';
import {fundingAnalysisApi, columnServiceApi, fundingVehiclesApi} from 'api';
import {useMergedState} from 'utils/customHooks';
import {FormControls} from './FormControls';
import {ModificationForm} from './ModificationForm';
import {Actions} from './Actions';
// Relative imports
import FundingAnalysisContext from '../../fundingAnalysisContext';
import styles from './fundingScenarioForm.theme.scss';

export const FundingScenarioForm = () => {
	// Browser State
	const {datasetId, scenarioId, action} = useParams();

	// Context State
	const fundingAnalysisContext = useContext(FundingAnalysisContext);

	// Local State
	const [showSpinner, setShowSpinner] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [activeConstraintGroup, setActiveConstraintGroup] = useState({isFVDefined: false});
	const [modelConstraintGroups, setModelConstraintGroups] = useState([]);
	const [fundingVehicleList, setFundingVehicleList] = useState([]);
	const [fundingModel, setFundingModel] = useState({});
	const [nameError, setNameError] = useState('');
	const [areActionsLocked, setActionsLocked] = useState(false);
	const [objectiveOptions, setObjectiveOptions] = useState([]);
	const [originalScenario, setOriginalScenario] = useState({});
	const [scenario, setScenario, replaceScenario] = useMergedState({
		_id: null,
		name: '',
		fundingModelId: null,
		scenarioLimit: '',
		constraintGroupId: null,
		snapshotDate: null,
		transferDate: null,
		fvSettings: [],
		modification: {},
		filters: [],
		reportGroupId: null,
	});
	const [inputErrors, setInputErrors] = useState({});

	// On dataset change
	useEffect(
		() => {
			// Fetch the scenario data then fetch the related model
			const fetchScenarioData = async id => {
				setTableLoading(true);
				const fvList = await fundingVehiclesApi.fetchFundingVehicleList(datasetId);
				setFundingVehicleList(fvList);
				const models = await fundingAnalysisContext.fetchModels(datasetId);
				const snapshots = await fundingAnalysisContext.fetchDatasetSnapshots(datasetId);
				const latestSnapshot = getLatestSnapshotDate(snapshots);
				// if this is a new scenario, generate default data
				const scenarioData =
					id && id !== 'create'
						? await fundingAnalysisApi.fetchScenario(id)
						: {
								_id: null,
								name: '',
								fundingModelId: models?.[0]?._id || null,
								scenarioLimit: '',
								constraintGroupId: null,
								fvSettings: [],
								modification: {},
								filters: [],
								snapshotDate: latestSnapshot,
								transferDate: latestSnapshot,
								reportGroupId: null,
						  };
				// substitute modification data if provided
				setOriginalScenario(_.cloneDeep(scenarioData));
				if (action === 'modify') {
					Object.assign(scenarioData, scenarioData.modification || {});
					delete scenarioData.modification;
				}
				// TODO: double check order here, we might need to set the constraint group before setOriginalScenario for action button logic
				const fvModel = models.find(m => m._id === scenarioData.fundingModelId) || models?.[0] || null;
				if (fvModel) {
					const constraintGroups = await fundingAnalysisApi.getConstraintGroups(fvModel._id);
					setModelConstraintGroups(constraintGroups);
					// if this is a new scenario (denoted by constraintGroupId not being set), set the first available constraint group for default value
					scenarioData.constraintGroupId ?? (scenarioData.constraintGroupId = constraintGroups?.[0]?._id);
					setActiveConstraintGroup(constraintGroups.find(cg => cg._id === scenarioData.constraintGroupId));
				}
				setScenario(scenarioData);
				setFundingModel(fvModel);
				setTableLoading(false);
			};

			setNameError('');
			fetchScenarioData(scenarioId);
		},
		[datasetId, scenarioId]
	);

	const calculateObjectiveOptions = async () => {
		let objectiveCol;
		const mappedCols = await columnServiceApi.getMappedColumnsForDataset(datasetId);
		const {assetColumnId: assetId, _id: cohortId} = mappedCols.assetCol;

		if (fundingModel.groupBy && fundingModel.groupBy !== assetId && fundingModel.groupBy !== cohortId) {
			// Only sum aggregates (defaults to balance)
			const assetCols = await columnServiceApi.getColumnsFromService(datasetId, {
				sources: {
					includeAggregateColumns: true,
				},
				filters: {
					dataTypes: ['numeric'],
				},
			});
			setObjectiveOptions(assetCols);
			objectiveCol = assetCols.find(col => col.displayName === 'Balance' && col.calculation === 'SUM');
		} else {
			// Only numeric assets
			const assetCols = await columnServiceApi.getColumnsFromService(datasetId, {
				sources: {
					includeAssetColumns: true,
					includeAssetCalculations: true,
					includeBusinessFunctions: true,
				},
				filters: {
					dataTypes: ['numeric'],
				},
			});
			setObjectiveOptions(assetCols);
			const balancedId = mappedCols.balanceCol.assetColumnId;
			objectiveCol = assetCols.find(col => col._id === balancedId);
		}
		return objectiveCol;
	};

	// On fundingModel change
	useEffect(
		() => {
			const onModelLoad = async () => {
				await calculateObjectiveOptions();
				const constraintGroups = await fundingAnalysisApi.getConstraintGroups(fundingModel._id);
				setModelConstraintGroups(constraintGroups);
				if (scenario) {
					setActiveConstraintGroup(constraintGroups.find(c => c._id === scenario.constraintGroupId));
				}
			};

			const onModelSelection = async () => {
				setScenario({fundingModelId: fundingModel._id});
				const objectiveCol = await calculateObjectiveOptions();
				const constraintGroups = await fundingAnalysisApi.getConstraintGroups(fundingModel._id);
				setModelConstraintGroups(constraintGroups);
				setActiveConstraintGroup(constraintGroups[0]);
				const formatSettings = (fvs = [], fvType) =>
					fvs.map(fv => ({
						fvId: fv.fvId,
						fvType: fvType,
						fvName: fv.fvName,
						objective: objectiveCol._id,
						fundingAmount: '',
						discountRate: '',
						isDrawdownInFull: false,
						fvIsUnencumbered: !!fundingVehicleList.find(f => f._id === fv.fvId)?.isUnencumbered,
					}));
				setScenario({
					fundingModelId: fundingModel._id,
					constraintGroupId: constraintGroups[0]._id,
					fvSettings: [
						...formatSettings(fundingModel.fvSources, 'source'),
						...formatSettings(fundingModel.fvTargets, 'target'),
					],
				});
			};

			if (fundingModel && fundingModel._id) {
				if (_.isEmpty(scenario.fvSettings)) {
					onModelSelection(); // new model
				} else {
					onModelLoad(); // loading scenario
				}
			}
		},
		[fundingModel]
	);

	if (!scenario || !modelConstraintGroups) {
		return (
			<div className={styles.loaderContainer} style={{background: '#FFF'}}>
				<KiProgressBar className={styles.loader} />
			</div>
		);
	}

	return (
		<React.Fragment>
			<div className={styles.root}>
				<FormControls
					scenario={scenario}
					setScenario={setScenario}
					nameError={nameError}
					setNameError={setNameError}
					fundingModel={fundingModel}
					setFundingModel={setFundingModel}
					modelConstraintGroups={modelConstraintGroups}
					setModelConstraintGroups={setModelConstraintGroups}
					activeConstraintGroup={activeConstraintGroup}
					setActiveConstraintGroup={setActiveConstraintGroup}
					setShowSpinner={setShowSpinner}
					replaceScenario={replaceScenario}
				/>
				<ModificationForm
					scenario={scenario}
					setScenario={setScenario}
					tableLoading={tableLoading}
					inputErrors={inputErrors}
					setInputErrors={setInputErrors}
					fundingModel={fundingModel}
					objectiveOptions={objectiveOptions}
				/>
			</div>
			<div className={styles.buttons}>
				<KiButtonSpinner showSpinner={showSpinner} showButton={false} primary={false} />
				<Actions
					scenario={scenario}
					modelConstraintGroups={modelConstraintGroups}
					nameError={nameError}
					areActionsLocked={areActionsLocked}
					inputErrors={inputErrors}
					originalScenario={originalScenario}
					setShowSpinner={setShowSpinner}
					setActionsLocked={setActionsLocked}
				/>
			</div>
		</React.Fragment>
	);
};
